import classNames from 'classnames'
import { FC, ReactNode, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FaIcon } from 'components/utilities/font-awesome'

export type Props = {
  path: string
  icon: string | IconDefinition
  backgroundIcon?: string
  isActive: boolean
  isDisabled?: boolean
  children: ReactNode
  setIsSidebarOpen?: (isOpen: boolean) => void
}

const Item: FC<Props> = ({ path, icon, backgroundIcon, isActive, isDisabled, children, setIsSidebarOpen }) => {
  const history = useHistory()

  const handleNavigate = useCallback(
    (e: any) => {
      e.preventDefault()

      history.push(path)

      setIsSidebarOpen && setIsSidebarOpen(false)
    },
    [history, path, setIsSidebarOpen]
  )

  return (
    <li className={classNames(isActive && 'active')}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={classNames(isDisabled && 'disabled-click')} onClick={handleNavigate}>
        {typeof icon === 'string' ? (
          <FaIcon {...{ icon, backgroundIcon }} />
        ) : (
          <FontAwesomeIcon icon={icon} className="mr-2" />
        )}
        &nbsp; {children}
      </a>
    </li>
  )
}

export default Item
