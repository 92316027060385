import asyncThunk from 'utils/asyncThunk'

export type Request = {
  query: string

  pending?: boolean
  direction?: 'requested' | 'proposed'
  start_date?: string
  end_date?: string
  user_id?: number
  organization_id?: number
  federation_id?: number
  budget_id?: number
  member_id?: number

  page?: number
  page_size?: number
  order_column?: 'id' | 'description' | 'amount' | 'created_at'
  order_direction?: 'asc' | 'desc'
}

export const listReimbursements = asyncThunk<Request, any>({
  name: 'reimbursements/list',
  uri: '/reimbursements',
  method: 'GET',
})
