import { FC, ReactElement, ReactNode, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import Portlet, { Body } from 'components/utilities/portlet'
import { Feature, FederationFeature } from 'types/feature'

import { Sheet, SheetContent, SheetTrigger } from './sheet'
import FeatureItem from './feature-item'
import Item, { Props as ItemProps } from './item'

type LinkProps = Omit<ItemProps, 'isActive'>

type Props = {
  items: ReactElement<{
    children: ReactElement<LinkProps>[]
  }>
  header?: ReactNode
  footer?: ReactNode
  level?: number
  children: ReactNode
}

const Settings: FC<Props> = ({ items, header, level = 4, children, footer }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const { pathname } = useLocation()
  const activePath = useMemo(() => {
    const parts = pathname.split('/')
    return parts[level]
  }, [pathname, level])

  const prefix = useMemo(() => {
    const parts = pathname.split('/')
    return parts.slice(0, level).join('/')
  }, [pathname, level])

  const itemsComponent = useMemo(
    () =>
      items.props.children
        .flat()
        .filter(c => !!c)
        .map(({ props: { path, ...props } }) => (
          <Item
            key={path}
            isActive={path === activePath}
            path={`${prefix}/${path}`}
            setIsSidebarOpen={setIsSidebarOpen}
            {...props}
          />
        )),
    [activePath, items.props.children, prefix]
  )

  return (
    <div>
      <Portlet boxed>
        <Body className="p-b-0">
          <Row className="layout layout-main-right layout-stack-sm">
            <Col xs={1} className="sidebar-trigger-wrapper visible-xs hidden-sm hidden-md hidden-lg !p-0">
              <Sheet open={isSidebarOpen} onOpenChange={setIsSidebarOpen}>
                <SheetTrigger asChild>
                  <div className="mt-[12px]">
                    <div className="sidebar-trigger sidebar-toggle hidden-print visible-xs-block p-0">
                      <i className="fa fa-bars"></i>
                    </div>
                  </div>
                </SheetTrigger>
                <SheetContent side="left" className="w-[360px] sm:w-[500px] bg-white z-[2000]">
                  <div>
                    {header}

                    <ul className="nav nav-layout-sidebar nav-stacked nav-no-border-first-and-last-child">
                      {itemsComponent}
                    </ul>

                    {footer}
                  </div>
                </SheetContent>
              </Sheet>
            </Col>

            <Col xs={11} sm={4} md={3} className="layout-sidebar hidden-print !visible pb-0 md:pb-[60px]">
              {header}

              <ul className="nav nav-layout-sidebar nav-stacked hidden-xs">{itemsComponent}</ul>

              {footer && <div className="pb-[10px]">{footer}</div>}
            </Col>

            <Col xs={12} sm={8} md={9} className="layout-main">
              <div className="tab-content stacked-content">{children}</div>
            </Col>
          </Row>
        </Body>
      </Portlet>
    </div>
  )
}

Object.assign(Settings, { Item, FeatureItem })

export default Settings as typeof Settings & {
  Item: FC<LinkProps>
  FeatureItem: FC<
    LinkProps & {
      feature: Feature | FederationFeature
    }
  >
}
