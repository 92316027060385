import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { File } from 'components/uploader'
import { useAPI } from 'hooks/useAPI'
import { createEventRegistration, Request } from 'thunks/event-registrations/create'
import { isAPIError, isGenericAPIError, processValidationErrors } from 'utils/asyncThunk'
import { notifyError } from 'utils/notify'

import { Event, EventRegistration } from './type'
// @ts-ignore
import { eventRegistrationQuery } from './type?query'

export type FormData = {
  _: string
  variable_amount: number | undefined
  tickets: number
  event_answers: (string | string[])[]
  documents: (File | undefined)[]
  event_optional_addons: number[]
  payment_method_id: number | undefined
}

const MIN_VARIABLE_AMOUNT = 0

const documentSchema = z.object({
  name: z.string(),
  preview: z.string(),
  s3_path: z.string(),
  mimetype: z.string(),
})

export default function useRegistrationForm(event: Event, onRegister: (registration: EventRegistration) => void) {
  const [docs, setDocs] = useState([])

  const schema = useMemo(() => {
    const answers = event.event_questions.map((q, i) => {
      const v = q.checkbox ? z.array(z.string()) : z.string()

      // Checkbox (multiple select)
      if (q.mandatory && q.select && q.checkbox) {
        return z.array(z.string().min(1, 'A response is required')).min(1, 'A response is required')
      }

      // Select
      if (q.mandatory && q.select && !q.checkbox) {
        return z.string().min(1, 'A response is required')
      }

      // File
      if (q.mandatory && q.with_image && !q.checkbox && !q.select) {
        return z.string().refine(() => Boolean(docs[i]), 'You must upload a document')
      }

      // Input
      if (q.mandatory && !q.select && !q.checkbox && !q.with_image) {
        return z.string().trim().min(1, 'A response is required')
      }

      return v
    })

    const variableAmount =
      event.cost_type === 'variable'
        ? z.number().min(MIN_VARIABLE_AMOUNT, `Amount must be greater than or equal to ${MIN_VARIABLE_AMOUNT}`)
        : z.number()

    const tickets = event.maximum_tickets
      ? z
          .number()
          .min(1, 'You must select at least 1 ticket')
          .max(event.maximum_tickets, `You can only select up to ${event.maximum_tickets} tickets`)
          .default(1)
      : z.number().min(1, 'You must select at least 1 ticket').default(1)

    return z.object({
      tickets: tickets,
      event_answers: z.tuple(answers as any),
      variable_amount: variableAmount,
      payment_method_id: z.number().optional(),
      event_optional_addons: z.array(z.number().default(0)),
      documents: z.array(z.union([z.undefined(), documentSchema])),
    })
  }, [docs, event])

  const form = useForm<FormData>({
    defaultValues: {
      tickets: 1,
      event_optional_addons: [],
      event_answers: [],
      documents: [],
      variable_amount: MIN_VARIABLE_AMOUNT,
    },
    resolver: zodResolver(schema),
  })

  // @ts-ignore
  const formDataDocuments = form.watch('documents')
  useEffect(() => {
    // @ts-ignore
    setDocs(formDataDocuments)
  }, [formDataDocuments])

  useEffect(() => {
    // Addons
    form.setValue(
      'event_optional_addons',
      event.optional_addons.map(() => 0)
    )

    // Answers
    const answers: (string | string[])[] = event.event_questions.map(question => (question.checkbox ? [] : ''))
    form.setValue('event_answers', answers)

    // Documents
    form.setValue(
      'documents',
      event.event_questions.map(() => undefined)
    )
  }, [event, form])

  const [register, { timer }] = useAPI<EventRegistration, Request>(createEventRegistration)
  const handleSubmit = useCallback(
    async (data: FormData) => {
      if (!event) return
      try {
        form.clearErrors()

        const [registration] = await register({
          query: eventRegistrationQuery,
          event_registration: {
            ...data,
            event_answers: event.event_questions.map((q, i) => {
              const answer = data.event_answers[i]

              if (q.with_image) {
                return {
                  ...q,
                  answer: answer,
                  document: docs[i],
                }
              }

              return {
                ...q,
                answer: answer,
              }
            }),
            event_optional_addons: event.optional_addons.map((a, i) => ({
              ...a,
              quantity: data.event_optional_addons[i],
            })),
            tickets: event.maximum_tickets ? data.tickets : undefined,
            variable_amount: data.variable_amount || 0,
            event_id: event.id,
            payment_method_id: data.payment_method_id ?? undefined,
          },
        })

        form.reset()

        onRegister(registration)
      } catch (err) {
        if (isAPIError<'event_registration'>(err)) {
          processValidationErrors('event_registration', err, form.setError)
        } else if (isGenericAPIError(err)) {
          notifyError(err.error ?? 'unable to register')
        } else {
          notifyError(`error: ${(err as any).message}`)
        }
      }
    },
    [docs, event, form, onRegister, register]
  )

  return [handleSubmit, { form, timer }] as const
}
