import { FC, useEffect } from 'react'
import { useAppDispatch, useAuthSelector } from 'store'

import morph from 'thunks/auth/morph'
import fetchRequiredActions from 'thunks/required-actions/fetch'

import { AdminMenu, AlumniMenu, FederationMenu, MemberMenu, ParentMenu, RootMenu } from './sidebar-menu'
import Item from './sidebar-menu/item'

const Sidebar: FC = () => {
  const { user } = useAuthSelector()

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!user) return
    if (!['member', 'admin', 'alumni', 'federation'].includes(user.role)) return

    dispatch(fetchRequiredActions({}))

    const interval = setInterval(() => {
      dispatch(fetchRequiredActions({}))
    }, 60_000 * 5) // 5 minutes

    return () => {
      clearInterval(interval)
    }
  }, [user, dispatch])

  if (!user) return null

  return (
    <div id="sidebar-menu" className="sidebar-grouped">
      {user.role === 'federation' && (
        <ul className="sidebar-menu c-pointer">
          <Item onClick={() => dispatch(morph({ id: user.id }))} icon="arrow-circle-right">
            Go to my alumni account
          </Item>
        </ul>
      )}

      {user.role === 'member' && <MemberMenu />}
      {user.role === 'admin' && <AdminMenu />}
      {user.role === 'parent' && <ParentMenu />}
      {user.role === 'alumni' && <AlumniMenu />}
      {user.role === 'federation' && <FederationMenu />}
      {user.role === 'root' && <RootMenu />}
    </div>
  )
}

export default Sidebar
