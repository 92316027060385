const alumniRoutes = {
  root: '/alumni/donate',
  donate: '/alumni/donate',
  review: '/alumni/review',
  settings: {
    root: '/alumni/settings',
    profile: '/alumni/settings/profile',
    password: '/alumni/settings/password',
    paymentMethods: '/alumni/settings/payment-methods',
    recurringPayments: '/alumni/settings/recurring-payments',
  },
  advising: {
    root: (id: number | ':id' = ':id') => `/alumni/advising/${id}`,
    show: (id: number | ':id' = ':id') => ({
      billPay: {
        root: `/alumni/advising/${id}/bill-pay`,
        show: (billPayId: number | ':bill_pay_id' = ':bill_pay_id') => `/alumni/advising/${id}/bill-pay/${billPayId}`,
      },
      budgets: {
        root: `/alumni/advising/${id}/budgets`,
        show: (budgetId: number | ':budget_id' = ':budget_id') => `/alumni/advising/${id}/budgets/${budgetId}`,
      },
      gCapital: {
        root: `/alumni/advising/${id}/gcapital`,
        show: (accountId: number | ':account_id' = ':account_id') => `/alumni/advising/${id}/gcapital/${accountId}`,
      },
      forms: {
        root: `/alumni/advising/${id}/forms`,
        show: (formId: number | ':form_id' = ':form_id') => `/alumni/advising/${id}/forms/${formId}`,
      },
      issuedCards: {
        show: (cardId: number | ':card_id' = ':card_id') => `/alumni/advising/${id}/issued-cards/${cardId}`,
      },
      members: {
        root: `/alumni/advising/${id}/members`,
      },
      requiredDocuments: {
        root: `/alumni/advising/${id}/required-documents`,
        show: (documentId: number | ':document_id' = ':document_id') =>
          `/alumni/advising/${id}/required-documents/${documentId}`,
      },
    }),
  },
  documentFolders: {
    root: '/alumni/document-folders',
    show: (id: number | ':id' = ':id') => `/alumni/document-folders/${id}`,
  },
  calendar: '/alumni/calendar',
  eventRegistrations: {
    root: '/alumni/event-registrations',
    new: (eventId: number | ':id' = ':id') => `/alumni/event-registrations/${eventId}/new`,
  },
  groups: {
    root: '/alumni/groups',
  },
  groupMembers: {
    root: '/alumni/group-members',
    show: (id: number | ':id' = ':id') => `/alumni/group-members/${id}`,
  },
  forms: {
    root: '/alumni/forms',
  },
  messageCategories: {
    root: '/alumni/message-board',
    show: (id: number | ':id' = ':id') => `/alumni/message-categories/${id}`,
    thread: (categoryId: number | ':id' = ':id', threadId: number | string = ':threadId') =>
      `/alumni/message-categories/${categoryId}/thread/${threadId}`,
    folder: (id: number | ':id' = ':id', folderId: number | string = ':folderId') =>
      `/alumni/message-categories/${id}/folder/${folderId}`,
  },
  voting: {
    root: '/alumni/voting',
    ended: '/alumni/voting/ended',
    finished: (id: number | ':id' = ':id') => `/alumni/voting/${id}/finished`,
    show: (id: number | ':id' = ':id') => `/alumni/voting/${id}`,
  },
  promissoryNotes: {
    root: '/alumni/promissory-note',
  },
}

export default alumniRoutes
