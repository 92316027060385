import { FC } from 'react'
import NumericInput from 'react-numeric-input'

import ConnectedField from 'components/form/connected-field'
import { Money } from 'components/utilities'

import { Event } from 'components/event/registration-form/type'

type Props = {
  addons: Event['optional_addons']
}

const AddOns: FC<Props> = ({ addons }) => {
  if (!addons || addons.length === 0) return <></>

  return (
    <ul className="list-unstyled">
      {addons.map((addon, i) => (
        <li key={i}>
          <ConnectedField
            type="number"
            defaultValue={0}
            min={0}
            label={
              <div>
                {addon.name}:{addon.cost && <Money amount={addon.cost} className="d-block text-muted" />}
              </div>
            }
            name={`event_optional_addons.${i}`}
            inputSize={2}
          >
            {({ value, onChange }) => (
              <NumericInput mobile min={0} value={value} onChange={onChange} className="form-control" />
            )}
          </ConnectedField>
        </li>
      ))}
    </ul>
  )
}

export default AddOns
